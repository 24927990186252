import React from 'react'
import classNames from 'classnames'
import {InView} from 'react-intersection-observer'

import {IconGlobe, IconCaretRight, IconCaretLeft} from '@components/Icon'

const FeatureProjects = () => {
  const [select, setSelect] = React.useState(0)
  const [width, setWidth] = React.useState(0)
  const [screenWidth, setScreenWidth] = React.useState(null)
  const projects = [
    {
      icon: 'images/icon-posmoni.svg',
      name: 'Posmoni',
      title: 'Posmoni <small>(เว็บภาษาญี่ปุ่น)</small>',
      body: 'บริการตรวจสอบ Content ทั้งรูปภาพ ข้อความ และวิดีโอ ด้วย AI อัจฉริยะ โดยทีมงานผู้เชี่ยวชาญจาก Data Wow บริษัทด้าน AI ชั้นนำของประเทศไทย',
      url: 'https://posmoni.com/',
    },
    {
      icon: 'images/icon-data-management-platform.svg',
      name: 'Accurately',
      title: 'Accurately',
      body: 'บริการที่ช่วยคุณ label ข้อมูลเพื่อนำไปใช้ในการ Auto ML พร้อมด้วยทีมผู้เชี่ยวชาญที่คอยช่วยเหลือให้งานคุณสำเร็จลุล่วง',
      url: 'https://accurately.ai/',
    },
    {
      icon: 'images/icon-pdpa-form.svg',
      name: 'PDPAForm.com',
      title: 'PDPAForm.com',
      body: 'จัดการคำขอเข้าถึงข้อมูลส่วนบุคคลจากลูกค้าของคุณได้เป็นระบบอย่างง่ายดาย ไม่พลาด ไม่โดนปรับ',
      url: 'https://pdpaform.com/',
    },
    {
      icon: 'images/icon-pdpa-ai.svg',
      name: 'PDPA.AI',
      title: 'PDPA.AI',
      body: 'ค้นหาข้อมูลส่วนตัวในไฟล์ต่างๆในบริษัทของคุณด้วย AI ลดความเสี่ยงข้อมูลส่วนตัวรั่วไหลตามข้อบังคับกฎหมาย PDPA',
      url: 'https://pdpa.ai/',
    },
    {
      icon: 'images/icon-pdpa-pro.svg',
      name: 'PDPA PRO',
      title: 'PDPA PRO',
      body: 'ระบบสร้างprivacy policy อย่างมืออาชีพ ภายใต้กฎหมายด้าน PDPA',
      url: 'https://pdpa.pro/',
      fullWidth: true,
    },
    {
      icon: 'images/icon-jott-ai.svg',
      name: 'Jott.ai',
      title: 'Jott.ai',
      body: 'LINE Bot ที่ช่วยบันทึกบทสนทนา คัดลอกไฟล์ไปเก็บไว้บนคลาวด์เพื่อให้ข้อมูลของคุณไม่หายอีกต่อไป',
      url: 'https://jott.ai/',
    },
  ]
  const selectedProject = projects[select]
  const showNextButton = select != projects.length - 1
  const showPreviousButton = select != 0
  const navRef = React.useRef()

  React.useEffect(() => {
    let timeoutId
    const handleResize = () => {
      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => {
        if (navRef.current) {
          const {width} = navRef.current.getBoundingClientRect()
          setWidth(width)
          setScreenWidth(window.innerWidth)
        }
      }, 200)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const scrollX = (position) => {
    // 0.9 is a threshold for scroll snapping
    navRef.current.scroll({left: position * 0.9, behavior: 'smooth'})
  }

  const renderNav = () => {
    return (
      <nav>
        <div className='FeatureProjects__nav-inner' ref={navRef}>
          {projects.map(({icon, name, fullWidth}, projectIndex) => (
            <button
              key={name}
              className={classNames('FeatureProjects__btn', {
                'FeatureProjects__btn--active': projectIndex == select,
                'FeatureProjects__btn--full': fullWidth,
              })}
              onClick={() => setSelect(projectIndex)}
            >
              {screenWidth && screenWidth <= 750 ? (
                <InView
                  as='span'
                  threshold={1}
                  onChange={(inView) => {
                    if (inView) {
                      setSelect(projectIndex)
                    }
                  }}
                >
                  <img src={icon} alt={name} loading='lazy' />
                </InView>
              ) : (
                <span>
                  <img src={icon} alt={name} loading='lazy' />
                </span>
              )}
              {name}
            </button>
          ))}
          {/* padding-right for scrolling */}
          <div></div>
        </div>

        {showPreviousButton && (
          <button
            className='FeatureProjects__navigator FeatureProjects__navigator--previous'
            onClick={() => {
              scrollX(width * (select - 1))
            }}
          >
            <IconCaretLeft />
          </button>
        )}

        {showNextButton && (
          <button
            className='FeatureProjects__navigator FeatureProjects__navigator--next'
            onClick={() => {
              scrollX(width * (select + 1))
            }}
          >
            <IconCaretRight />
          </button>
        )}
      </nav>
    )
  }

  // TODO: re-write these with proper html structure
  return (
    <div className='FeatureProjects'>
      <h3>กรุณาเลือกโปรเจคที่คุณสนใจ</h3>
      <h3>รายละเอียด</h3>

      {renderNav()}

      <div className='FeatureProjects__detail'>
        <div>
          <p className='title-p' dangerouslySetInnerHTML={{__html: selectedProject.title}}></p>
          <p>{selectedProject.body}</p>

          <a
            href={selectedProject.url}
            title={selectedProject.name}
            target='_blank'
            rel='nofollow noopener noreferrer'
          >
            <IconGlobe />
          </a>
        </div>
      </div>
    </div>
  )
}

export default FeatureProjects
